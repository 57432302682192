/** Reposition Osano cookie consent button **/
.osano-cm-widget {
  left: 10px !important;
  bottom: 25px !important;
}

/** Fix pagination on Mobile WalletConnect wallet list **/
#walletconnect-wrapper .walletconnect-modal__footer {
  flex-wrap: wrap;
}
