.walletBalance {
  @apply mb-3;
}

.row {
  @apply uppercase;

  & > span {
    @apply text-gray-10;
  }
}
